import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { map, Observable, of } from 'rxjs'
import { OfferService } from '../offer/offer.service'
import { StorageService } from '../storage/storage.service'
import { STORAGE_KEYS } from '../storage/storage.constants'
import { BankService } from '../bank/bank.service'

export interface Session {
  authToken: string
  user: any
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authSession!: Session | undefined
  constructor(private router: Router,
              private ofs: OfferService,
              private bns: BankService,
              private storage: StorageService) { }

  init() {
    return this.storage.get<Session>(STORAGE_KEYS.SESSION).then(r => {
      this.authSession = r
    })
  }

  getToken() {
    return this.authSession?.authToken;
  }

  async signIn (req: Session) {
    this.authSession = req
    await this.storage.set(STORAGE_KEYS.SESSION, req)
    return this.authSession
  }

  async signOut() {
    await this.storage.remove(STORAGE_KEYS.SESSION)
    await this.storage.remove(STORAGE_KEYS.REF_CODES)
    this.ofs.clear()
    this.bns.clear()
    this.authSession = undefined
    this.router.navigate(['/login']).then()
  }

  isAuthenticated(): Observable<boolean> {
    if (this.authSession?.authToken) {
      return of(true)
    } else {
      //this.router.navigate(['/login']).then()
      return of(false)
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated()
    .pipe(
      map(r => {
        if (r) {
          return true
        } else {
          this.router.navigate(['/login']).then()
          return false
        }
      })
    )
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state)
  }
}
