import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs'
import { Onboarding } from '../../model/onboarding.model'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  data$!: BehaviorSubject<Onboarding>

  constructor (private http: HttpClient, private router: Router) {
  }

  get () {
    return this.http.get<Onboarding>(environment.backendEndpoint + '/onboarding')
    .pipe(tap(v => this.data$
      ? this.data$.next(v)
      : this.data$ = new BehaviorSubject(v)))
  }

  post (data: Onboarding) {
    return this.http.post<Onboarding>(environment.backendEndpoint + '/onboarding', data)
    .pipe(tap(r => r && this.data$.next(r)))
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.get()
    .pipe(
      map(r => {
        if (r.onboardingStatus?.code === 'YES') {
          return true
        } else {
          this.router.navigate(['/onboarding']).then()
          return true
        }
      }),
      catchError(e => {
        return of(true)
      })
    )
  }

  clear () {
    delete (this as any).data$
  }
}
