// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import package_json from '../../package.json';
import { Functions } from './environment.prod'
import { trigger } from '@angular/animations'
export const environment = {
  production: false,
  backendEndpoint: 'https://dev-api.cashli.in/v2',
  cdnEndpoint: 'https://cdn.cashli.in/',
  version: package_json.version,
  appMetric: "4e2f5343-2912-46f5-acac-8d8588511f73",
  supportEndpoint: 'https://dev-support.cashli.in',
  feature: {
    [Functions.referralProgram]: true,
    [Functions.supportChat]: true, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/94
    [Functions.appUpdate]: true,
    [Functions.productsCatalog]: true, // https://gitlab.com/cashli/cashlisub/common-board/-/issues/96
    [Functions.webhooks]: true, // https://gitlab.com/cashli/cashlisub/common-board/-/issues/107
    [Functions.cashback]: true, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/71
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

/**
 * https://api.cashli.in
 * https://dev-api.cashli.in
 */
