import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Pipe({
  name: 'trustVideo'
})
export class TrustVideoPipe implements PipeTransform {

  constructor (private sanitizer: DomSanitizer) {
  }

  transform(url: any, ...args: unknown[]): SafeResourceUrl {
    // Appending an ID to a YouTube URL is safe.
    // Always make sure to construct SafeValue objects as
    // close as possible to the input data so
    // that it's easier to check if the value is safe.
    const regexp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
    const match = url.match(regexp)

    return this.sanitizer.bypassSecurityTrustResourceUrl(match && match[2].length === 11
      ? 'https://www.youtube.com/embed/' + match[2]
      : url);
  }

}
