import { Injectable } from '@angular/core';
import { AppMetrica, YAMParams, YAMUserProfile, YAMUserProfileId } from 'capacitor-appmetrica-plugin'
import { environment } from '../../../environments/environment'

type AnalyticsEvents = 'otpRequested' | 'otpCompleted' | 'onboardingCompleted' |
  'onboardingSkipped' | 'profileCompleted' | 'profileSkipped' | 'homeView' |
  'bankView' | 'bankProfileCompleted' | 'withdrawalCompleted' | 'educationView' |
  'offerGroupView' | 'offerSubgroupView' | 'offerView' | 'offerCopyLink' | 'offerShareLink' |
 'offer18Created' | 'subAgentLinkCreated' | 'offersFilterClick' | 'offerFilterApplied' |
  'leadView' | 'leadsFilterClick' | 'leadsFilterApplied' | 'leadsDownloaded' |
  'supportListFromHome' | 'supportListFromMenu' | 'supportMessageCreated' |
  'referralCodeGenerated' | 'referralCodeShared' | 'referralCodeCopied' |
  'referralProgramBannerClicked' | 'referralCodeApplied' |
  'profileChangeCompleted' |
  'clickedOnProductType' | 'offerCopyLinkFromProduct'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() {
    this.init()
  }

  init() {
    AppMetrica.activate({
      apiKey: environment.appMetric,
      logs: !environment.production
    }).then();
  }

  setUserProfileId(data:  YAMUserProfileId) {
    AppMetrica.setUserProfileId(data).then()
  }

  setUserProfile(profile: YAMUserProfile & {generalUserType: string | undefined}) {
    AppMetrica.reportUserProfile(profile).then()
  }

  event(name: AnalyticsEvents, params?: YAMParams) {
    AppMetrica.reportEvent({name, params}).then()
  }

}
