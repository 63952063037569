import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http'
import { AuthService } from '../auth/auth.service'
import { Observable, tap, catchError, throwError } from 'rxjs'
import { ToastService } from '../toast/toast.service'

@Injectable({
  providedIn: 'root'
})
export class InterceptService implements HttpInterceptor {

  constructor(
    private auth: AuthService,
    private toast: ToastService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.auth.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getToken()}`
        }
      });
    }

    return next.handle(request)
    .pipe(
      tap(_ => {
        //this.snack.dismiss()
      }),
      catchError((e: HttpErrorResponse) => {
        if (e.status === 403) {
          this.toast.error('Method not allowed ' + request.method + ' ' + e.url).then()
          this.auth.signOut().then();
        } else if (e.status === 0) {
          this.toast.error('Backend communication error').then()
          return throwError(() => e.statusText)
        } else {
          this.toast.info(e?.error?.failure || e?.error).then()
          return throwError(() => e?.error?.failure || e?.error)
        }
        return throwError(() => e)
      })
    );
  }
}

