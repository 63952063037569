import { Component, OnInit } from '@angular/core';
import { AppUpdateService } from '../../../services/app-update/app-update.service'
import { IonicModule } from '@ionic/angular'

@Component({
  selector: 'app-force-update',
  templateUrl: './force-update.component.html',
  styleUrls: ['./force-update.component.scss'],
  imports: [
    IonicModule
  ],
  standalone: true
})
export class ForceUpdateComponent  implements OnInit {

  constructor(public aus: AppUpdateService) { }

  ngOnInit() {}

}
