import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { InterceptService } from './services/intercept/intercept.service'
import { SharedModule } from './shared/shared.module'
import { ServicesModule } from './services/services.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { registerLocaleData } from '@angular/common'

import localeIn from '@angular/common/locales/en-IN'
registerLocaleData(localeIn)

@NgModule({
  declarations: [AppComponent],
  imports: [
    //BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    ServicesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideNgxMask(),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
