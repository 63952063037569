import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import { IonContent, IonItem, ScrollDetail } from '@ionic/angular'

export interface ScrollMenuItem {
  title: string
  offset: number
}

@Component({
  selector: 'app-scroll-menu',
  templateUrl: './scroll-menu.component.html',
  styleUrls: ['./scroll-menu.component.scss']
})
export class ScrollMenuComponent implements AfterViewInit{
  @Input() offsets!: ScrollMenuItem[]
  @ViewChild('selector') selector!: any
  @ViewChild(IonContent) content!: IonContent;

  currentActive = 0;

  ngAfterViewInit() {
    const scroller = document.querySelector(".app-content ion-content");
    scroller?.addEventListener("ionScroll", e => {
      const offset = (e as any).detail.scrollTop + 5
      const scrollIndex = this.offsets.findIndex((o,ind,arr) =>
        offset >= o.offset && arr.length !== ind + 1 ? offset < arr[ind+1].offset : true)
      if (scrollIndex !== -1) {
        this.currentActive = scrollIndex + 1
        this.selector.el.setAttribute('style',
            `display: block; transform: translateY(${(this.currentActive - 1) * 48}px);`);
      }
      if (this.currentActive) {
        this.selector.el.setAttribute('style',
            `display: block; transform: translateY(${(this.currentActive - 1) * 48}px);`);
      }
    })
    this.scrollTo(0)
  }

  scrollTo(offset: number) {
    const content = document.querySelector(".app-content ion-content") as unknown as IonContent
    content.scrollToPoint(0,offset,500).then()
    //scroller?.scrollTo({top: offset, behavior: 'smooth'});
  }
}
