import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms'

const errorTexts = {
  notMatch: "Don't match",
  notFromList: "Don't match with list",
  pattern: "Don't match with pattern",
  required: "Required field",
  email: 'Type correct email',
  minlength: "Don't match with pattern",
  maxlength: "Don't match with pattern",
  notUniq: 'This value is already exist',
}
@Pipe({
  name: 'formErrorText'
})
export class FormErrorTextPipe implements PipeTransform {

  transform(errors: ValidationErrors | null | undefined): unknown {
    // @ts-ignore
    const errorCodes = errors && Object.keys(errors).map(k => errorTexts[k] || k)
    console.debug(errorCodes)
    return errorCodes && errorCodes.join(', ');
  }

}
