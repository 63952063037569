import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core'
import { Functions } from '../../../environments/environment.prod'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DisplayService {

  constructor() { }

  get isLargeScreen() {
    return window.innerWidth > 1200
  }

  get isNative() {
    return Capacitor.isNativePlatform()
  }

  get isProduction() {
    return environment.production
  }

  isAvailable(func: Functions) {
    return environment.feature[func]
  }
}
