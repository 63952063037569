import { OfferService } from '../services/offer/offer.service'

export enum OfferStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE_AND_SHOWN = 'INACTIVE_AND_SHOWN'
}

export interface Brand {
  id: number
  created: string
  title: string
  description: string
  imageUrl: string
}

export interface Category {
  id: number
  parent: Category
  code: string
  title: string
  description: string
  hidden: boolean
  imageUrl: string
}

export interface Goal {
  id: number,
  onOfferScreen: boolean,
  discount: number,
  code: string,
  description: string,
  type: string,
  currency: string,
  country: string,
  minVolume: string,
  value: number,
  alwaysVisible: false,
  confirmationTime: 0,
  isPercentage: false,
  postbackPrefix: string, // trackingAction?
}

export interface Faq {
  id: number
  question: string
  answer: string
  position: number
}

export interface OfferTrafficType {
  traffic: {
    id: number
    title: string
  },
  allowed: boolean
}

export enum MediaStatus {
  ACTIVE = 'ACTIVE'
}


export interface OfferMedia {
  id: number
  title: string
  imageUrl: string
  trackingUrl: string
  placeholder: string
  description: string
  width: number
  height: number
  type: null
  status: MediaStatus
}

export interface Offer {
  id: number
  created: string
  title: string
  status: OfferStatus
  campaignType: 'PARTNER_CAMPAIGN' | 'LEAD_GENERATION_CAMPAIGN' // https://gitlab.com/cashli/cashlisub/common-board/-/issues/145
  brand: Brand
  category: Category
  iconUrl: string
  videoUrl: string
  shortDescription: string
  fullDescription: string
  offerWarning: string
  leadApprovalTime: number
  howToSell: {
    instruction: string
    rulesToFollow: string
    termsOfUse: string
    requiredDocuments: string
    educationalVideoLink: string
    video_1: string
    video_2: string
    video_3: string
    header_1: string
    header_2: string
    header_3: string
  }
  leadApprovalDescription: string
  leadConfirmTime: number
  leadConfirmDescription: string
  textData: {
    pinCodes: string
    campaignFlow: any
    feesAndCharges: string
    telegram: string
    whatsapp: string
    sms: string
    profit: string
  }
  goals: Goal[]
  media: OfferMedia[]
  faq: Faq[]
  trafficTypes: OfferTrafficType[]
  pinCodeRestricted: boolean
  incomeCalculationMode: string
  potentialIncome: number
  potentialIncomePercent: number

  vkycLink: string
  vkycDescription: string

  applicationEducationalLink: string
  applicationEducationalText: string
  applicationStatusLink: string

  offerCashbackSettingsId: number
}

export interface OfferLink {
  id: number
  offerId: number
  shortUrl: string
  subAgentName: string
}

export enum LeadStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  //PAID = 'PAID',
}

export enum GoalStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  PAID = 'PAID',
  DECLINED = 'DECLINED'
}

export interface LeadAction {
  id: number
  lead: number
  alwaysVisible: boolean
  approved: string
  declined: boolean
  executed: string
  goal: {
    code: string
    currency: string
    description: string
    discount: number
    isPercentage: boolean
    margin: number
    paidValue: number
    trackingAction: number
    value: number
  }
  onOfferScreen: boolean
  planned: any
  postback: any
  sortOrder: number
  status: GoalStatus
  type: string
}

export interface Lead {
  actions: LeadAction[]
  clickId: number
  applicationId: number
  clickSource: null
  created: string
  extUrlParams: string
  ip: string
  lastUpdate: string
  offer: {
    id: number
    brand: Brand
    incomeCalculationMode: string
    leadApprovalTime: number
    status: OfferStatus
    title: string
    videoUrl: string

    vkycDescription: string
    vkycLink: string

    applicationEducationalLink: string
    applicationEducationalText: string
    applicationStatusLink: string
  }
  madeByPreLanding: boolean
  potentialIncome: number
  potentialIncomePercent: number
  status: LeadStatus
  subAgentName: string
  trafficSourceId: number
  urlShortener: string
  userAgent: string
  userData: {
    phoneNumber: number
    name: string
  }
}

export const categoryColor = (code: string) => {
  switch (code) {
    case 'SAVINGS_ACCOUNT':
      return 'warning'
    case 'LOAN':
      return 'secondary'
    case 'CREDIT_CARD':
      return 'tertiary'
    default:
      return 'tertiary'
  }
}

export const categoryIcon = (code: string) => {
  console.debug('categoryIcon', code.toLocaleLowerCase())
  return `assets/icon/${code.toLocaleLowerCase()}.png`
}
