import { Component, OnInit } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@capgo/capacitor-navigation-bar'
import { AuthService } from './services/auth/auth.service'
import { AnalyticsService } from './services/analytics/analytics.service'
import { SplashScreen } from '@capacitor/splash-screen';
import { AppUpdateService } from './services/app-update/app-update.service'
import { firstValueFrom } from 'rxjs'
import { environment } from '../environments/environment'
import { Functions } from '../environments/environment.prod'
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthService,
              private aus: AppUpdateService,
              private analytics: AnalyticsService) {}

  async ngOnInit () {
    try {
      await StatusBar.setStyle({ style: Style.Light });
      await StatusBar.setOverlaysWebView({ overlay: false });
      await StatusBar.setBackgroundColor({color: '#ffffff'})
      await NavigationBar.setNavigationBarColor({color: '#ffffff'})
      await SplashScreen.hide()
      if (environment.feature[Functions.appUpdate]) {
        await firstValueFrom(this.aus.getAppVersion())
      }
    } catch(e) {
      console.info('init error', e)
    }
    await this.auth.init().then()
  }
}
