import { Pipe, PipeTransform } from '@angular/core';
import { OfferService } from '../../services/offer/offer.service'
import { Offer } from '../../model/offer.model'

@Pipe({
  name: 'offerById'
})
export class OfferByIdPipe implements PipeTransform {

  constructor (private ofs: OfferService) {

  }

  transform(id: number | undefined, ...args: unknown[]): Offer | undefined {
    return id && this.ofs.offers$.value.find(o => o.id === id) || undefined
  }

}
