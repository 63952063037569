export enum STORAGE_KEYS {
  SESSION = 'CASHLI_SESSION',
  LAST_USER_ID = 'LAST_USER_ID',
  IS_INTRO_SHOWN = 'IS_ONBOARDING_SHOWN',
  IS_ONBOARDING_SHOWN = 'IS_ONBOARDING_SHOWN',
  IS_PROFILE_DATA_SHOWN = 'IS_PROFILE_DATA_SHOWN',
  OFFER18_LINK = 'OFFER18_LINK',

  OFFERS_FILTER = 'OFFERS_FILTER',
  OFFERS_RECENT_PINS = 'OFFERS_RECENT_PINS',

  REF_CODES = 'REF_CODES'
}
