import { Referee, TrafficSource } from './traffic-source.model'
import { Offer } from './offer.model'

export enum TransactionStatus {
  PAID = 'PAID',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
}

export enum TransactionKind {
  RECALCULATION= 'RECALCULATION',
  WITHDRAWAL = 'WITHDRAWAL',
  BONUS = 'BONUS',
  REFERRAL_BONUS = 'REFERRAL_BONUS',
  PAYMENT = 'PAYMENT'
}

export interface Bonus {
  inPercent: boolean
  trigger: 'REGISTRATION' | 'LEAD_ACTION',
  leadCountFrom: number
  leadCountTo: number
  receiver: 'REFERRAL' | 'REFEREE'
}

export interface BankTransaction {
  amount: number
  leadActionOrdinal: number,
  bonus: Bonus
  clickId: number
  created: string
  executed: string
  id: string
  kind: TransactionKind
  leadActionId: string
  leadId: string
  offer: Offer
  planned: string
  status: TransactionStatus
  trafficSource: TrafficSource
  referee: Referee
  version: number
}

export interface Bank {
  availableAmount: number
  minWithdrawalAmount: number
  transactions: BankTransaction[]
}
