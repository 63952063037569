import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment'

@Pipe({
  name: 'moment'
})
export class MomentPipe implements PipeTransform {

  transform(data: string | number, format: string = ''): string {
    return moment.unix(data as number).format(format);
  }

}
