import { Pipe, PipeTransform } from '@angular/core';
import { Offer } from '../../model/offer.model'

@Pipe({
  name: 'maxEarnings'
})
export class MaxEarningsPipe implements PipeTransform {

  transform(arr: Offer[], type: 'number' | 'percent'): number {
    const earnings = arr
    .map(o => type === 'number' ? o.potentialIncome : o.potentialIncomePercent)
    .filter(v => v)
    return earnings && Math.max(...earnings) || 0
  }

}
