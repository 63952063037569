import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { DisplayService } from '../display/display.service'
import { STORAGE_KEYS } from '../storage/storage.constants'
import { StorageService } from '../storage/storage.service'
import { AuthService } from '../auth/auth.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient,
              private storage: StorageService,
              private authService: AuthService,
              private router: Router,
              private display: DisplayService) { }

  get() {
    return this.http.get<any>(environment.backendEndpoint + '/otp')
  }

  otp (request: any, marketingUrl: string | undefined) {
    return this.http.post<any>(environment.backendEndpoint + '/otp',
      {
        ...request,
        marketingUrl, //await MarketingUrl.getReferrerDetails().then(),
        source: this.display.isNative ? 'MOBILE' : 'WEB'
      })
  }

  auth(request: any) {
    return this.http.post<any>(environment.backendEndpoint + '/auth', request)
  }

  delete(id: number) {
    return this.http.delete<any>(environment.backendEndpoint + '/private/video/' + id)
  }

  async canActivate (): Promise<boolean> {
    if (this.authService.authSession?.authToken) {
      this.router.navigate(['/main']).then()
      return false
    } else if (await this.storage.get(STORAGE_KEYS.IS_INTRO_SHOWN)) {
      return true
    } else {
      this.router.navigate(['/intro']).then()
      return false
    }
  }
}
