import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { Category, Lead, LeadStatus, Offer, OfferLink, OfferStatus } from '../../model/offer.model'
import { BehaviorSubject, combineLatest, delay, filter, map, Subject, tap } from 'rxjs'
import { DisplayService } from '../display/display.service'
import moment from 'moment';
import { Product } from '../../model/product.model'
import { SearchResult } from '../../model/search-result.model'

interface ShortUrlRequest {
  offerId: number | undefined
  socNetwork?: string
  subAgentName?: string
  shortener?: string
}

export interface LeadsFilter {
  tsFrom: string | null,
  tsTo: string | null,
  offerIds: number[],
  statuses: LeadStatus[],
  subAgents: string[],
  offer18: string | undefined,
  applicationIdFragment: string | undefined
}

export interface OffersFilter {
  pinCode: string
}

export interface ProductSearchFilter {
  category: number
}

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  offers$!: BehaviorSubject<Offer[]>
  searchResult$!: BehaviorSubject<Offer[]>
  products$!: BehaviorSubject<Product[]>
  categories$!: BehaviorSubject<Category[]>
  searchProducts$!: BehaviorSubject<Product[]> | undefined
  links$!: BehaviorSubject<OfferLink[]>
  leads$!: BehaviorSubject<Lead[]>

  leadListOffset = 0
  leadListLimit!: number

  constructor (private http: HttpClient, private display: DisplayService) {
    this.leadListLimit = this.display.isLargeScreen ? 30 : 10
  }

  list () {
    return this.http.get<Offer[]>(environment.backendEndpoint + '/offers')
    .pipe(tap(v => this.offers$
      ? this.offers$.next(v)
      : this.offers$ = new BehaviorSubject(v)))
  }

  search (params: any) {
    const search = Object.keys(params).map((k: string) =>
      `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`).join('&');
    return this.http.get<Offer[]>(environment.backendEndpoint + `/offers?${search}`)
    .pipe(tap(v => this.searchResult$
      ? this.searchResult$.next(v)
      : this.searchResult$ = new BehaviorSubject(v)))
  }

  checkPin (offerId: number, pin: number) {
    return this.http.get<Offer[]>(environment.backendEndpoint + `/offers?pinCode=${pin}`)
    .pipe(map(res => res.some(o => o.id === offerId)))
  }

  links () {
    return this.http.get<OfferLink[]>(environment.backendEndpoint + '/shortUrl')
    .pipe(tap(v => this.links$ ? this.links$.next(v) : this.links$ = new BehaviorSubject(v)))
  }

  deleteLink (id: number) {
    return this.http.delete<OfferLink>(environment.backendEndpoint + '/shortUrl/' + id)
    .pipe(tap(() => this.links().subscribe()))
  }

  products () {
    return this.http.get<Product[]>(environment.backendEndpoint + '/products')
    .pipe(
      map(res => res
        .filter(p => p.productStatus?.code === 'ACTIVE')
        .sort((a, b) => a.productType > b.productType ? 1 : -1)
      ),
      tap(r => console.debug('products:', r)),
      tap(v => this.products$
        ? this.products$.next(v)
        : this.products$ = new BehaviorSubject(v)))
  }

  categories () {
    return this.http.get<Category[]>(environment.backendEndpoint + '/category')
    .pipe(
      map(res => res.filter(c => !c.hidden)),
      tap(r => console.debug('categories:', r)),
      tap(v => this.categories$
        ? this.categories$.next(v)
        : this.categories$ = new BehaviorSubject(v)))
  }

  /**
   * Поиск продуктов
   * @param filter
   */
  searchProducts (filter: ProductSearchFilter) {
    this.searchProducts$ = undefined
    return this.http.post<SearchResult<Product> | Product[]>(environment.backendEndpoint + '/products/filter', filter)
    .pipe(
      delay(100),
      map(res =>
        ((res as SearchResult<Product>).content || res)
        .filter(p => p.productStatus?.code === 'ACTIVE')
        .sort((a, b) => a.productType > b.productType ? 1 : -1)),
      tap(v => this.searchProducts$
        ? this.searchProducts$.next(v)
        : this.searchProducts$ = new BehaviorSubject(v)))
  }

  postLink (request: ShortUrlRequest) {
    return this.http.post<OfferLink>(environment.backendEndpoint + '/shortUrl', request)
    .pipe(tap(() => this.links().subscribe()))
  }

  leads2 () {
    return this.http.get<Lead[]>(environment.backendEndpoint +
      `/leads?offset=${this.leadListOffset}&limit=${this.leadListLimit}`)
    .pipe(tap(v => this.leads$
      ? this.leads$.next(this.leadListOffset && this.leads$.getValue().concat(v) || v)
      : this.leads$ = new BehaviorSubject(v)))
  }

  leads (filter?: LeadsFilter) {
    const request = {
      tsFrom: moment(filter?.tsFrom || new Date(2022, 1, 1)).startOf('day').toISOString(),
      tsTo: moment(filter?.tsTo || new Date()).endOf('day').toISOString(),
      offerIds: filter?.offerIds || [],
      statuses: (filter && filter?.statuses?.length !== 0) ? filter?.statuses : Object.values(LeadStatus),
      applicationIdFragment: filter?.applicationIdFragment || undefined,
      offset: this.leadListOffset,
      limit: this.leadListLimit
    }
    return this.http.post<Lead[]>(environment.backendEndpoint + `/leads`, request)
    .pipe(tap(v => this.leads$
      ? this.leads$.next(this.leadListOffset && this.leads$.getValue().concat(v) || v)
      : this.leads$ = new BehaviorSubject(v)))
  }

  getLead (clickId: number) {
    const request = {
      clickIds: [clickId]
    }
    return this.http.post<Lead[]>(environment.backendEndpoint + `/leads`, request)
    .pipe(map(r => r && r[0]))
  }


  downloadLeads (filter: LeadsFilter) {
    const request = {
      tsFrom: moment(filter?.tsFrom || new Date(2022, 1, 1)).startOf('day').toISOString(),
      tsTo: moment(filter?.tsTo || new Date()).endOf('day').toISOString(),
      offerIds: filter?.offerIds || [],
      statuses: filter?.statuses?.length !== 0 ? filter?.statuses : Object.values(LeadStatus),
      applicationIdFragment: filter?.applicationIdFragment || undefined,
      offer18: filter?.offer18 || 'ALL',
      subAgents: filter?.subAgents || [],
    }
    return this.http.post(environment.backendEndpoint + '/leads/csv', request, { responseType: 'blob' })
  }

  preload () {
    return combineLatest([...this.offers$.getValue().map(o => this.loadImage(o.brand.imageUrl))])
  }

  loadImage (url: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.addEventListener('load', () => resolve(img))
      img.addEventListener('error', (err) => reject(err))
      img.src = url
    })
  }

  getCategories (
    type: 'group' | 'subgroup',
    groupId?: number,
    subGroupId?: number,
    isSetFilter = false): Map<number, Offer[]> {

    let map = (isSetFilter ? this.searchResult$.value : this.offers$.value)
    .filter(o => groupId === undefined || (groupId && o.category.parent.id === groupId))
    .filter(o => subGroupId === undefined || (subGroupId && o.category.id === subGroupId))
    .reduce((store, item) => {
      const key = type === 'group' ? item.category.parent.id : item.category.id
      if (!store.has(key)) {
        store.set(key, [item])
      } else {
        store.get(key)?.push(item)
      }
      return store
    }, new Map<number, Offer[]>())

    map = new Map([...map.entries()]
    .sort((a, b) => a[1].length > b[1].length ? -1 : 1))

    return map

  }

  getProduct (offerId: number): Offer | undefined {
    return this.offers$.value.find(o => o.id === offerId)
  }

  clear () {
    delete (this as any).offers$
    delete (this as any).products$
    delete (this as any).links$
    delete (this as any).leads$
  }

  minLoanAmount (): number {
    return Math.min(...this.products$.value
    .filter(p => p?.productLoan?.loanAmountFrom > 0)
    .map(p => p?.productLoan?.loanAmountFrom))
  }

  maxLoanAmount (): number {
    return Math.max(0, ...this.products$.value
    .map(p => p?.productLoan?.loanAmountTo || 0))
  }

  minLoanTenure (): number {
    return Math.min(...this.products$.value
    .filter(p => p?.productLoan?.tenureFrom > 0)
    .map(p => p?.productLoan?.tenureFrom))
  }

  maxLoanTenure (): number {
    return Math.max(0, ...this.products$.value
    .map(p => p?.productLoan?.tenureTo || 0))
  }

  minInterestRate (categoryId?: number): number {
    return Math.min(...this.searchProducts$?.value
    .filter(p => !categoryId || p.category?.parent?.id === categoryId || p.category?.id === categoryId)
    .filter(p => p?.productInterest?.interestRateFrom > 0)
    .map(p => p?.productInterest?.interestRateFrom) || [0])
  }

  maxInterestRate (categoryId?: number): number {
    return Math.max(0, ...this.searchProducts$?.value
    .filter(p => !categoryId || p.category?.parent?.id === categoryId || p.category?.id === categoryId)
    .map(p => p?.productInterest?.interestRateTo || 0) || [0])
  }

  minBalance (): number {
    return Math.min(...this.searchProducts$?.value
    .filter(p => p?.productSavingsAccount?.minimumBalanceToOpenAc >= 0)
    .map(p => p?.productSavingsAccount?.minimumBalanceToOpenAc) || [0])
  }

  maxBalance (): number {
    return Math.max(0, ...this.searchProducts$?.value
    .map(p => p?.productSavingsAccount?.minimumBalanceToOpenAc || 0) || [0])
  }

  minMonthlyBalance (): number {
    return Math.min(...this.searchProducts$?.value
    .filter(p => p?.productSavingsAccount?.minimumMonthlyBalance >= 0)
    .map(p => p?.productSavingsAccount?.minimumMonthlyBalance) || [0])
  }

  maxMonthlyBalance (): number {
    return Math.max(0, ...this.searchProducts$?.value
    .map(p => p?.productSavingsAccount?.minimumMonthlyBalance || 0) || [0])
  }

  categoryList (): Category[] {
    return [...new Set(this.categories$.value
    .filter(c => this.offers$.value.some(o => o.category.id === c.id))
    .map(c => [c, c?.parent, c?.parent?.parent])
    .flat()
    .filter(c => c?.id)
    .map(c => JSON.stringify(c)))
    ].map(c => JSON.parse(c))
  }

  categoryListChildren (code: string): Category[] {
    console.debug('categoryListChildren', code, this.categoryList())
    return [...new Set(
      this.categoryList()
      .filter(c => c.parent?.parent?.code === code || c.parent?.code === code)
      .map(c => c.parent?.parent?.title ? c.parent : c)
      .map(c => JSON.stringify(c)))]
    .map(c => JSON.parse(c))
  }

  topProductCategories (code: string, pos: number = 3) {
    return this.categoryListChildren(code)
    .filter(c => this.products$.value.filter(p =>
      p.category?.parent?.id === c.id || p.category.id === c.id)?.length > 0)
    .sort((a, b) =>
      this.products$.value.filter(p =>
        p.category?.parent?.id === a.id || p.category.id === a.id)?.length >
      this.products$.value.filter(p =>
        p.category?.parent?.id === b.id || p.category.id === b.id)?.length ? -1 : 1).slice(0, pos)
  }

  getCategory (id: number): Category | undefined {
    //console.debug('getCategory', id, this.categoryList())
    return this.categoryList()?.find(c => c.id === id)
  }

}
