import { Pipe, PipeTransform } from '@angular/core';
import {convert} from 'html-to-text';

@Pipe({
  name: 'truncateHtml'
})
export class TruncateHtmlPipe implements PipeTransform {

  transform (text: string, ...args: unknown[]): string {
    const options = {
      selectors: [
        { selector: 'li', options: { itemPrefix: ' * ' } }
      ]
    }
    return text
      //? text.replace(/<(?:.|\n)*?>/gm, ' ').replace(/(&nbsp;|<br>|<br \/>)/g, '')
    ? convert(text).replace(/^ +/gm, '').replace(/(^[ \t]*\n)/gm, "")//.replace(/^\s+|\s+$/gm,'')
    : text
  }

}
