import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { STORAGE_KEYS } from './storage.constants'

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  set(key: string, value: any) {
    return Preferences.set({ key, value: JSON.stringify(value)})
  }

  get<T>(key: string): Promise<T> {
    return Preferences.get({ key}).then(r => r.value ? JSON.parse(r.value) : r.value)
  }

  remove(key: string) {
    return Preferences.remove({ key })
  }

  clear () {
    this.remove(STORAGE_KEYS.SESSION).then()
  }
}
