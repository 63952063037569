<section class="ion-padding">
  <h3>Update require</h3>
  <p>We have lunched new and improved app. Please update to continue using the app.</p>
</section>
<ion-footer class="ion-no-border ion-padding-bottom no-color">
  <ion-toolbar>
    <ion-button (click)="aus.update()" class="ion-margin-horizontal"
                style="min-height: 48px"
                expand="block" color="primary" shape="round" fill="solid">
      <span>Update</span>
    </ion-button>
  </ion-toolbar>
</ion-footer>
