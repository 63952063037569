import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular'
import { DisplayService } from '../display/display.service'

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastController: ToastController,
              private display: DisplayService) { }

  private async present(message: string,
                        icon: string,
                        color: string = '',
                        position: 'top' | 'middle' | 'bottom' = this.display.isLargeScreen ? 'bottom' : 'top') {
    const toast = await this.toastController.create({
      icon: icon,
      //positionAnchor: "ion-footer",
      message: message,
      duration: 5000,
      position: position,
      color: color
    });
    await toast.present();
  }

  async info(message: string) {
    return this.present(message, 'chatbox-ellipses-outline')
  }

  async success(message: string) {
    return this.present(message, 'checkmark-circle')
  }

  async error(message: string) {
    return this.present(message, 'hand-left-outline', 'danger')
  }
}
