import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TruncateHtmlPipe } from './pipes/truncate-html.pipe';
import { TrustVideoPipe } from './pipes/trust-video.pipe'
import { ScrollMenuComponent } from './components/scroll-menu/scroll-menu.component'
import { IonicModule } from '@ionic/angular'
import { CommonModule } from '@angular/common';
import { OfferByIdPipe } from './pipes/offer-by-id.pipe';
import { MomentPipe } from './pipes/moment.pipe';
import { FormErrorTextPipe } from './pipes/form-error-text.pipe';
import { MaxEarningsPipe } from './pipes/max-earnings.pipe';

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  providers: [
    TruncateHtmlPipe
  ],
  declarations: [
    TruncateHtmlPipe,
    TrustVideoPipe,
    ScrollMenuComponent,
    OfferByIdPipe,
    MomentPipe,
    FormErrorTextPipe,
    MaxEarningsPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    TruncateHtmlPipe,
    TrustVideoPipe,
    ScrollMenuComponent,
    OfferByIdPipe,
    MomentPipe,
    FormErrorTextPipe,
    MaxEarningsPipe,
  ]
})
export class SharedModule {}
